import "./styles.scss";

import React, { useState } from "react";
import axios from "axios";
import { navigate } from "gatsby";

import { useLocale } from "@context/locale";

import ArrowIcon from "@icons/arrow.svg";

function ReservationForm({ agreement, howFastOption, offerOptions }) {
	const { t } = useLocale();
	const form_id = 690;
	const [send, setSend] = useState(false);
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [procedure, setProcedure] = useState("");
	const [howFast, setHowFast] = useState("");
	const [legal, setLegal] = useState("");

	const formSubmit = (e) => {
		e.preventDefault();
		setSend(true);

		console.log(e, phone);

		const formData = new FormData();
		formData.set("firstName", name);
		formData.set("email", email);
		formData.set("phone", phone);
		formData.set("procedure", procedure);
		formData.set("howFast", howFast);
		formData.set("legal", legal);

		axios({
			method: "post",
			url: `https://cms.dream-factory.pl/wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback/`,
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(() => {
				console.log("Submit succes");
				navigate(t("message-sent_link"));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleFocus = (e) => {
		const label_id = `label-${e.target.id}`;
		document.getElementById(label_id).classList.add("focus");
	};

	const handleFocusOver = (e) => {
		const label_id = `label-${e.target.id}`;
		if (!e.target.value)
			document.getElementById(label_id).classList.remove("focus");
	};

	const handleChange = (e) => {
		switch (e.target.name) {
			case "reservation-name":
				setName(e.target.value);
				break;
			case "tel":
				setPhone(e.target.value);
				break;
			case "email":
				setEmail(e.target.value);
				break;
			case "reservation-procedure":
				setProcedure(e.target.value);
				break;
			case "reservation-howFast":
				setHowFast(e.target.value);
				break;
			default:
				return null;
		}

		const label_id = `label-${e.target.id}`;
		if (e.target.value) {
			document.getElementById(label_id).classList.add("focus");
		} else {
			document.getElementById(label_id).classList.remove("focus");
		}
	};

	return (
		<form onSubmit={formSubmit} className="reservation-form">
			<label htmlFor="name" id="label-reservation-name">
				{t("Imię")}
				<sup>*</sup>
			</label>
			<input
				type="text"
				name="reservation-name"
				id="reservation-name"
				onFocus={handleFocus}
				onBlur={handleFocusOver}
				onChange={(e) => setName(e.target.value)}
				required
			/>
			<label htmlFor="tel" id="label-reservation-tel">
				{t("Telefon")}
				<sup>*</sup>
			</label>
			<input
				type="tel"
				name="tel"
				id="reservation-tel"
				onFocus={handleFocus}
				onBlur={handleFocusOver}
				onChange={handleChange}
				required
			/>
			<label htmlFor="email" id="label-reservation-email">
				{t("Email")}
			</label>
			<input
				type="email"
				name="email"
				id="reservation-email"
				onFocus={handleFocus}
				onBlur={handleFocusOver}
				onChange={handleChange}
			/>
			<div className="reservation-form__select">
				<label
					htmlFor="reservation-procedure"
					id="label-reservation-procedure"
				>
					{t("Zabieg")}
					<sup>*</sup>
				</label>
				<select
					name="reservation-procedure"
					id="reservation-procedure"
					onFocus={handleFocus}
					onBlur={handleFocusOver}
					onChange={handleChange}
					required
				>
					<option value="" disabled selected>
						{" "}
						&nbsp;
					</option>
					{offerOptions.map((offer) => (
						<option value={offer.title}>{offer.title}</option>
					))}
				</select>
				<ArrowIcon width={10} height={13} />
			</div>

			<div className="reservation-form__select">
				<label
					htmlFor="reservation-how-fast"
					id="label-reservation-how-fast"
				>
					{t("Jak szybko mamy oddzwonić?")}
					<sup>*</sup>
				</label>

				<select
					name="reservation-how-fast"
					id="reservation-how-fast"
					onFocus={handleFocus}
					onBlur={handleFocusOver}
					onChange={handleChange}
					required
				>
					<option value="" disabled selected>
						{" "}
						&nbsp;
					</option>
					{howFastOption.map((option) => (
						<option value={option.text}>{option.text}</option>
					))}
				</select>
				<ArrowIcon width={10} height={13} />
			</div>

			<p className="reservation-form__note">
				{t("Pola wymagane")}
				<sup>*</sup>
			</p>
			<div className="reservation-form__submit">
				<label
					htmlFor="reservation-privacy-policy"
					id="reservation-label_privacy-policy"
				>
					<input
						type="checkbox"
						name="reservation-privacy-policy"
						id="reservation-privacy-policy"
						onChange={() => setLegal(!legal)}
						required
					/>
					<span className="checkmark" />
					<div dangerouslySetInnerHTML={{ __html: agreement }} />
				</label>
				<input
					type="submit"
					value={send ? t("wysyłanie...") : t("Wyślij")}
				/>
			</div>
		</form>
	);
}

export default ReservationForm;
