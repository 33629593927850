import "./styles.scss";

import React from "react";

function Step({ number, title, subtitle }) {
  return (
    <div className="step">
      <span className="step__number">{number}</span>
      <h3 className="step__title">{title}</h3>
      <p className="step__subtitle">{subtitle}</p>
    </div>
  );
}

export default Step;
